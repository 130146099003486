<template>
  <div class="header product-container">
    <div class="product-detail-header">
      <div class="product-detail-header-navigation justify-space-between">
        <div class="d-flex align-center">
          <router-link to=".">
            <img
              class="avatar"
              src="@/assets/img/back-button.png"
              alt="view"
              width="44px"
              height="44px"
            />
          </router-link>
          <div class="product-detail-info">
            <h2>Product detail</h2>
            <h6 v-if="product.title">{{ product.title }}</h6>
          </div>
        </div>
        <div
          class="left d-flex justify-space-between flex-wrap align-center gap-5"
        >
          <BaseBtn
            v-if="
              product.stock === 0 &&
              product.importListStatus !== 'imported' &&
              product.importListStatus !== 'created'
            "
            class="w-100"
            @clicked="notifyMe(id)"
          >
            Notify me
          </BaseBtn>
          <BaseBtn
            v-else-if="
              product.importListStatus === 'not_imported' ||
              product.importListStatus === 'no_retailer'
            "
            class="btn_green btn_green_importList"
            @clicked="addToImportList(product.id)"
          >
            Add to Import List
          </BaseBtn>

          <BaseBtn
            v-if="product.importListStatus === 'created'"
            class="vgang-button card-button card_button_red"
            @clicked="RemoveFromImportList(product.importListID)"
            >Remove from Import List
          </BaseBtn>
        </div>
      </div>
    </div>
    <div class="product-detail">
      <div class="meta-container">
        <div v-if="product">
          <div class="product_detail_card card-bg" style="display: flex">
            <div class="gallery_container" v-if="showImages">
              <hooper group="group1" class="hooper_large_image">
                <slide v-for="img in images.large_size" :key="img.id">
                  <img
                    class="slide_image_large"
                    :src="img.url"
                    :alt="product.title"
                  />
                </slide>
              </hooper>

              <hooper
                v-if="images.normal_size.length > 1"
                group="group1"
                class="hooper_small_image"
                :itemsToShow="3"
              >
                <slide v-for="img in images.normal_size" :key="img.id">
                  <img
                    class="slide_image_small"
                    :src="img.url"
                    :alt="product.title"
                  />
                </slide>

                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
            </div>
            <div class="product-info">
              <h1 v-if="product.title" class="product-title">
                {{ product.title }}
              </h1>
              <div class="delivery-detail-wrapper">
                <div class="flex-wrapper">
                  <span class="from">Made In: </span>
                  <img
                    v-if="
                      product && product.shippings && product.shippings.length
                    "
                    :src="
                      require(`@/assets/img/flags/${product.country.iso2}.svg`)
                    "
                    alt="flag"
                    class="flag"
                  />
                  <img
                    v-else
                    :src="require(`@/assets/img/no-image.png`)"
                    alt="flag"
                    class="flag"
                  />
                </div>

                <div v-if="product.brandName" class="vertical-devider1"></div>
                <router-link
                  v-if="routeName"
                  :to="'/sh-retailer/supplierProfile/' + product.sellerID"
                >
                  <span v-if="product.brandName" class="from-seller"
                    >By:<span class="brandName">
                      {{ product.brandName }}</span
                    ></span
                  ></router-link
                >
                <router-link
                  v-else
                  :to="'/retailer/supplierProfile/' + product.sellerID"
                >
                  <span v-if="product.brandName" class="from-seller"
                    >By:<span class="brandName">
                      {{ product.brandName }}</span
                    ></span
                  ></router-link
                >

                <div v-if="product.tags.length" class="tags-type">
                  <div
                    :class="[`tag ${colors[index % 10]}`]"
                    v-for="(tag, index) in product.tags"
                    :key="index"
                  >
                    {{ tag }}
                  </div>
                </div>
              </div>

              <div class="">
                <div
                  class="each each-title-container d-flex justify-space-between"
                >
                  <div
                    v-if="
                      product.minPrice &&
                      product.maxPrice &&
                      product.minPrice !== product.maxPrice
                    "
                    class="cost-title"
                  >
                    Product cost: {{ toCurrency(product.minPrice) }} to
                    {{ toCurrency(product.maxPrice) }}
                  </div>
                  <div
                    v-else-if="
                      product.minPrice &&
                      (!product.maxPrice ||
                        product.minPrice === product.maxPrice)
                    "
                    class="cost-title"
                  >
                    Product cost: {{ toCurrency(product.minPrice) }}
                    <br />
                    <button
                      class="vgang_btn   Variation_link"
                      @click="scrollToBottom()"
                    >
                      Product Variations
                    </button>
                  </div>

                  <div
                    v-if="
                      product.minRetailPrice &&
                      product.maxRetailPrice &&
                      product.minRetailPrice !== product.maxRetailPrice
                    "
                    class="cost-title"
                  >
                    Suggested selling price:
                    {{ toCurrency(product.minRetailPrice) }} -
                    {{ toCurrency(product.maxRetailPrice) }}
                    <br />
                    <div
                      v-if="product?.wholesale_price_percentage"
                      class="tags-type"
                      style="float: right"
                    >
                      <div class="tag Yellow">
                        {{ product?.wholesale_price_percentage }}% Profit Margin
                        Guarantee
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      product.minRetailPrice &&
                      (!product.maxRetailPrice ||
                        product.minRetailPrice === product.maxRetailPrice)
                    "
                    class="cost-title"
                  >
                    Suggested selling price:
                    {{ toCurrency(product.minRetailPrice) }}

                    <br />
                    <div
                      v-if="product?.wholesale_price_percentage"
                      class="tags-type"
                      style="float: right"
                    >
                      <div class="tag Yellow">
                        {{ product?.wholesale_price_percentage }}% Profit Margin
                        Guarantee
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 25px">
                <v-row
                  v-if="product && product.shippings && product.shippings[0]"
                >
                  <div class="textship">Shipping info</div>

                  <div
                    v-if="
                      ShowMoreShippingInfo &&
                      product &&
                      product.shippings &&
                      product.shippings[0]
                    "
                    class="d-flex justify-space-between align-center gap-2 my-3"
                  >
                    <img
                      v-if="product?.shippings[0]?.countryISO2"
                      :src="
                        require(`@/assets/img/flags/${product.shippings[0]?.countryISO2}.svg`)
                      "
                      alt="flag"
                      class="flag mr-2"
                    />
                    <img
                      v-else
                      :src="require(`@/assets/img/no-image.png`)"
                      alt="flag"
                      class="flag"
                    />
                    <div style="width: 25.33%">
                      <span class="item1 mb-0">
                        {{ product?.shippings[0].country }}
                      </span>
                    </div>

                    <div class="d-flex justify-start" style="width: 33.33%">
                      <span class="item2">
                        {{ product?.shippings[0].min_delivery_date }} -
                        {{ product?.shippings[0].max_delivery_date }} working
                        days
                      </span>
                    </div>
                    <div style="width: 33.33%" class="d-flex justify-start">
                      <span
                        v-if="
                          product?.shippings[0].min_price &&
                          product?.shippings[0].max_price &&
                          product?.shippings[0].min_price !==
                            product?.shippings[0].max_price
                        "
                        class="item"
                      >
                        {{
                          toCurrency(
                            product?.shippings[0].min_price,
                            product?.shippings[0].currency
                          )
                        }}
                        -
                        {{
                          toCurrency(
                            product?.shippings[0].max_price,
                            product?.shippings[0].currency
                          )
                        }}
                      </span>
                      <span
                        v-if="
                          product?.shippings[0].min_price &&
                          product?.shippings[0].max_price &&
                          product?.shippings[0].min_price ===
                            product?.shippings[0].max_price
                        "
                        class="item"
                      >
                        {{
                          toCurrency(
                            product?.shippings[0].min_price,
                            product?.shippings[0].currency
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          product?.shippings[0].min_price &&
                          !product?.shippings[0].max_price
                        "
                        class="item"
                      >
                        {{
                          toCurrency(
                            product?.shippings[0].min_price,
                            product?.shippings[0].currency
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          !product?.shippings[0].min_price &&
                          product?.shippings[0].max_price
                        "
                        class="item"
                      >
                        {{
                          toCurrency(
                            product?.shippings[0].max_price,
                            product?.shippings[0].currency
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          !product?.shippings[0].min_price &&
                          !product?.shippings[0].max_price
                        "
                        class="item"
                      >
                        Free
                      </span>
                    </div>
                  </div>
                  <div v-if="!ShowMoreShippingInfo">
                    <div
                      class="d-flex justify-space-between align-center gap-2 my-3"
                      v-for="(shipping, shippingIndex) in product.shippings"
                      :key="shippingIndex"
                    >
                      <img
                        v-if="shipping.countryISO2 && product.shippings.length"
                        :src="
                          require(`@/assets/img/flags/${shipping.countryISO2}.svg`)
                        "
                        alt="flag"
                        class="flag mr-2"
                      />
                      <img
                        v-else
                        :src="require(`@/assets/img/no-image.png`)"
                        alt="flag"
                        class="flag"
                      />
                      <div style="width: 25.33%">
                        <span class="item1 mb-0">
                          {{ shipping.country }}
                        </span>
                      </div>

                      <div class="d-flex justify-start" style="width: 33.33%">
                        <span class="item2">
                          {{ shipping.min_delivery_date }} -
                          {{ shipping.max_delivery_date }} working days
                        </span>
                      </div>
                      <div style="width: 33.33%" class="d-flex justify-start">
                        <span
                          v-if="
                            shipping.min_price &&
                            shipping.max_price &&
                            shipping.min_price !== shipping.max_price
                          "
                          class="item"
                        >
                          {{
                            toCurrency(shipping.min_price, shipping.currency)
                          }}
                          -
                          {{
                            toCurrency(shipping.max_price, shipping.currency)
                          }}
                        </span>
                        <span
                          v-if="
                            shipping.min_price &&
                            shipping.max_price &&
                            shipping.min_price === shipping.max_price
                          "
                          class="item"
                        >
                          {{
                            toCurrency(shipping.min_price, shipping.currency)
                          }}
                        </span>
                        <span
                          v-else-if="shipping.min_price && !shipping.max_price"
                          class="item"
                        >
                          {{
                            toCurrency(shipping.min_price, shipping.currency)
                          }}
                        </span>
                        <span
                          v-else-if="!shipping.min_price && shipping.max_price"
                          class="item"
                        >
                          {{
                            toCurrency(shipping.max_price, shipping.currency)
                          }}
                        </span>
                        <span
                          v-else-if="!shipping.min_price && !shipping.max_price"
                          class="item"
                        >
                          Free
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    class="mt-3"
                    v-if="
                      product &&
                      product.shippings &&
                      product.shippings.length > 1
                    "
                  >
                    <div v-if="!ShowMoreShippingInfo" class="text">
                      <button
                        class="read-more mr-1"
                        @click="ShowMoreShippingInfo = !ShowMoreShippingInfo"
                      >
                        <span class="mr-1">less</span>
                        <img
                          loading="lazy"
                          src="@/assets/img/arrow-up.png"
                          alt="view"
                          width="12px"
                          height="8px"
                        />
                      </button>
                    </div>
                    <div v-else class="text">
                      <button
                        class="read-more mr-1"
                        @click="ShowMoreShippingInfo = !ShowMoreShippingInfo"
                      >
                        <span class="mr-1">more</span
                        ><img
                          width="12px"
                          height="8px"
                          src="@/assets/img/arrow-down.png"
                          loading="lazy"
                          alt="view"
                        />
                      </button>
                    </div>
                  </div>
                </v-row>
              </div>
            </div>
          </div>

          <div class="card-bg">
            <div class="product-description">
              <h1 class="product-name">Product description</h1>
              <div v-if="product.description">
                <div v-if="!readMore" class="text">
                  <div :inner-html.prop="product.description"></div>
                  <button class="read-more mr-1" @click="readMore = !readMore">
                    <span class="mr-1">less</span>
                    <img src="@/assets/img/arrow-up.png" alt="view" />
                  </button>
                </div>
                <div v-else class="text">
                  <div
                    :inner-html.prop="
                      product.description | truncate(520, '...')
                    "
                  ></div>
                  <button class="read-more mr-1" @click="readMore = !readMore">
                    <span class="mr-1">more</span
                    ><img src="@/assets/img/arrow-down.png" alt="view" />
                  </button>
                </div>
              </div>
            </div>
            <div class="divider"></div>
            <div class="product-variants-list">
              <h1 style="margin-bottom: 10px" class="product-name">
                Product Variations
              </h1>
              <h3 class="variants-desc" style="margin-bottom: 10px">
                Import Variations for Products with 10 or More Items in Stock,
                Except for Unique Pieces.
              </h3>
              <div class="table-container">
                <table class="import-list-table">
                  <thead class="theader">
                    <tr>
                      <th></th>
                      <th>Size</th>
                      <th
                        v-for="(optionName, index) in AvailableOptions"
                        :key="index"
                      >
                        {{ optionName }}
                      </th>
                      <th>Inventory</th>
                      <th style="border-top-right-radius: 10px">Price</th>
                      <v-menu
                        offset-y
                        :close-on-content-click="false"
                        transition="scale-transition"
                        left
                      >
                        <div class="menu_container">
                          <div class="d-flex-column">
                            <div
                              v-for="(
                                shipping, indexShipping
                              ) in variants.shippings"
                              :key="indexShipping"
                              @click="SelectedShippingIndex = indexShipping"
                              class="countries clickable py-3 d-flex align-center"
                            >
                              <img
                                :src="
                                  require(`@/assets/img/flags/${shipping.countryISO2}.svg`)
                                "
                                :alt="`${shipping.countryISO2}`"
                                class="flag"
                              />
                              <span class="country">{{
                                shipping.countryISO2
                              }}</span>
                            </div>
                            <div
                              class="countries clickable py-3 d-flex align-center"
                            ></div>
                          </div>
                        </div>
                      </v-menu>
                    </tr>
                  </thead>
                  <tbody class="tbody">
                    <tr
                      v-for="variant in variants"
                      :key="variant.id"
                      class="each-row"
                    >
                      <td class="table-image-container">
                        <img
                          v-if="
                            variant.variantImages &&
                            variant.variantImages[0] &&
                            variant.variantImages[0].thumbnailImage
                          "
                          class="table-image pa-3"
                          :src="`${variant.variantImages[0].thumbnailImage}`"
                          alt="variant_image"
                        />
                        <img
                          v-else
                          class="table-image pa-3"
                          :src="`${product.productImages[0].thumbnailImage}`"
                        />
                      </td>
                      <td class="table-size-container">
                        {{ findSize(variant.options) }}
                      </td>

                      <td
                        v-for="(
                          optionName, optionNameIndex
                        ) in AvailableOptions"
                        :key="optionNameIndex"
                      >
                        <span>
                          {{ OptionValue(variant, optionName) }}
                        </span>
                      </td>
                      <td class="">
                        <div class="tags-type">
                          <div
                            v-if="variant.stock <= 5 && variant.stock >= 1"
                            class="stock_alert tag yellow"
                            style="
                              font-size: 14px;
                              color: #fff !important;
                              padding: 15px;
                            "
                          >
                            {{ variant.stock }}

                            Low stock
                          </div>

                          <div
                            v-else-if="variant.stock == 0"
                            class="stock_low tag red"
                            style="
                              font-size: 14px;
                              color: #fff !important;
                              padding: 15px;
                            "
                          >
                            Out of stock
                          </div>

                          <div
                            v-else-if="variant.stock > 5"
                            class="stock_low tag green"
                            style="
                              font-size: 14px;
                              color: #fff !important;
                              padding: 15px;
                            "
                          >
                            {{ variant.stock }} in stock
                          </div>
                        </div>
                      </td>
                      <td>{{ toCurrency(variant.price) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="product-description product-policy mt-15">
              <h1 class="title">Return Policy</h1>
              <!-- // todo: this next section should come from the database  -->
              <div class="bold-text">
                <!-- <p class="bold-text">Within 15 days of receiving the order</p> -->
                <p class="bold-text">
                  Consumers have a
                  {{
                    product.refundDaysDuration == 0
                      ? "non refundable"
                      : product.refundDaysDuration
                  }}-day withdrawal. Returned items must have tags still on and
                  be returned in original product packaging. Returned items must
                  have no visible signs of wear or use.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseBtn from "@/components/bases/BaseBtn.vue";
import { mapGetters, mapActions } from "vuex";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
// Services
import RetailerProducts from "@/services/RetailerProducts";
import SimpleBtn from "@/components/bases/SimpleBtn.vue";
export default {
  name: "Productdetail",
  components: { SimpleBtn, Hooper, Slide, HooperNavigation, BaseBtn },
  metaInfo: {
    // Set the meta description
    meta: [
      {
        name: "vang, product details",
        content: "product details",
      },
    ],
  },
  data() {
    return {
      body: "product.description",
      readMore: false,
      ShowMoreShippingInfo: true,
      thumbs: [],
      normal_sizes: [],
      zoomerOptions: {
        zoomFactor: 2, // scale for zoomer
        pane: "container-round", // three type of pane ['pane', 'container-round', 'container']
        hoverDelay: 300, // how long after the zoomer take effect
        namespace: "zoomer", // add a namespace for zoomer component, useful when on page have mutiple zoomer
        move_by_click: false, // move image by click thumb image or by mouseover
        scroll_items: 3, // thumbs for scroll
        choosed_thumb_border_color: "#ffffff", // choosed thumb border color
        scroller_button_style: "fill",
        scroller_position: "bottom",
        zoomer_pane_position: "right",
      },
      hooperSettings: {
        itemsToShow: 2,
        centerMode: false,
        breakpoints: {
          800: {
            centerMode: false,
            itemsToShow: 3,
          },
          1000: {
            itemsToShow: 3,
            pagination: "fraction",
          },
        },
      },
      product: "",
      tags: [],
      colors: [
        "Red",
        "Green",
        "Yellow",
        "Blue",
        "Bluesky",
        "Gray",
        "Red",
        "Green",
        "Yellow",
        "Blue",
        "Bluesky",
        "Gray",
      ],

      plan: "",
      images: {},
      readMore: true,
      showImages: false,
      variants: "",
      showVariants: false,
      importErrorMessage: "",
      showImportError: false,
      showImportSuccess: false,
      product_variations_dialog: false,
      hooperSettings: {
        itemsToShow: 2,
        centerMode: true,
        breakpoints: {
          800: {
            centerMode: false,
            itemsToShow: 3,
          },
          1000: {
            itemsToShow: 6,
            pagination: "fraction",
          },
        },
      },
    };
  },

  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    truncate: function (text, length, clamp) {
      return text.length > length ? text.slice(0, length) + clamp : text;
    },
  },
  mounted() {
    this.getProduct(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      alertSuccess: "alert/alertSuccess",
      alertError: "alert/alertError",
      newAlert: "alert/newAlert",
    }),
    defaultCurrency() {
      return this.product.sellerCurrency && this.product.sellerCurrency !== ""
        ? this.product.sellerCurrency
        : "EUR";
    },

    routeName() {
      const url_string = window.location.href;
      const url = new URL(url_string);
      return url.pathname.includes("/sh-retailer");
    },

    formattedBody() {
      if (this.readMore) {
        return this.product.description;
      }

      return `${this.product.description.slice(0, 520).trim()}...`;
    },

    AvailableOptions() {
      if (this.variants.length > 1) {
        const allOptions = this.variants
          .map((variant) => {
            if (variant.options) return variant.options;
            else return [];
          })
          .flat();

        if (allOptions) {
          const availableOptions = [...new Set(allOptions)];
          const UniqueOptionNames = [
            ...new Set(
              availableOptions.map((option) => {
                if (option.name) {
                  return option.name;
                }
              })
            ),
          ];

          return UniqueOptionNames || [];
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    getMaxPrice() {
      return Math.max(
        ...this.product.shippings.map((shipping) => shipping.max_price)
      );
    },
  },
  methods: {
    ...mapActions([
      "retailer/importProductToList",
      "retailer/removeImportedList",
      "alert/clear",
      "alert/newAlert",
    ]),
    getProduct(id) {
      RetailerProducts.getProduct(id)
        .then((response) => {
          this.product = response.data;
          const id = this.product.id;
          this.getProductVariants(id);
          this.i = 1;
          for (const val of response.data.productImages) {
            this.i = this.i + 1;
            this.thumbs.push({
              id: this.i,
              url: val.thumbnailImage,
            });
            this.normal_sizes.push({
              id: this.i,
              url: val.image,
            });
          }
          this.showImages = true;
          this.images = {
            thumbs: this.thumbs,
            normal_size: this.normal_sizes,
            large_size: this.normal_sizes,
          };
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProductVariants(id) {
      RetailerProducts.getProductVariants(id)
        .then((response) => {
          this.variants = response.data.products;

          if (this.variants.length >= 1) {
            this.showVariants = true;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    scrollToBottom() {
      window.scrollTo(0, 1200);
    },
    orderSamples() {
      alert("Order Samples id =" + this.$route.params.id);
    },
    SetNewProfit() {
      const variants = this.variants;
      variants.forEach((variant) => {
        variant.sales_price =
          Math.round(
            (variant.price * (this.sale_price_profit / 100) +
              variant.sales_price) *
              100
          ) / 100;
      });
    },
    toCurrency: function (price, currency) {
      if (!currency) {
        currency = this.defaultCurrency;
      }
      var formatter = new Intl.NumberFormat("en-EU", {
        style: "currency",
        currency: currency,
      });
      const curerncy_price = formatter.format(price);

      return curerncy_price;
    },
    async addToImportList(id) {
      const importListID = await this["retailer/importProductToList"](id);
      if (importListID) {
        this.product.importListID = importListID;
        this.showImportSuccess = true;
        this.showAlertSuccess = true;
        this.showImportError = false;
        this.product.importListStatus = "created";
        // clear the alert after 2 seconds
      } else {
        this.showImportError = true;
        this.showAlertError = true;
        this.showImportSuccess = false;
        // clear the alert after 2 seconds
      }
    },
    async RemoveFromImportList(id) {
      const response = await this["retailer/removeImportedList"](id);

      if (response) {
        this.showImportSuccess = true;
        this.showAlertSuccess = true;
        this.showImportError = false;
        this.product.importListStatus = "not_imported";
        // clear the alert after 2 seconds
      } else {
        this.showImportError = true;
        this.showAlertError = true;
        this.showImportSuccess = false;
        // clear the alert after 2 seconds
      }
    },
    getFlag(iso) {
      var flag = require.context("@/assets/img/flags", false, /\.svg$/);
      return flag("./" + iso + ".svg");
    },
    calculateProfit(cost, retailerPrice) {
      return cost - retailerPrice;
    },
    findSize(options) {
      var index = options.findIndex((p) => p.name == "Size");
      if (index != "-1") {
        return options[index].value;
      } else {
        return "-";
      }
    },
    findColor(options) {
      var index = options.findIndex((p) => p.name == "Color");
      if (index != "-1") {
        return options[index].value;
      } else {
        return "-";
      }
    },
    findMaterial(options) {
      var index = options.findIndex((p) => p.name == "Material");
      if (index != "-1") {
        return options[index].value;
      } else {
        return "-";
      }
    },
    async notifyMe(id) {
      this.showAlertError = true;
      this["alert/newAlert"]({
        type: "error",
        message: "we will let you when it becomes available",
      });
      setTimeout(async () => {
        this.showAlertError = false;
        this["alert/clear"]();
      }, 3000);

      // clear the alert after 2 seconds
    },
    OptionValue(variant, optionName) {
      let val = " --- ";
      for (let i = 0; i < variant.options.length; i++) {
        const option = variant.options[i];
        if (option.name === optionName) {
          val = option.value;
          break;
        } else {
          continue;
        }
      }

      return val;
    },
  },
};
</script>
<style lang="scss" scoped>
.variants-desc {
  font-size: 14px !important;
  font-weight: 400;
}
.btn_green {
  background-color: #008060;
  border-radius: 4px;
  width: 100%;
}
.btn_green_importList {
  @include breakpoint(420px) {
    font-size: 12px !important;
  }
}
.remove-import-List-btn {
  color: #202223;
  border-radius: 4px;
}
.remove-from-shop-btn {
  color: #d72c0d;
  border-color: #d72c0d;
  border-radius: 4px;
}
.Variation_link {
  border: none;
  border-bottom: #008f4a 2px solid;
  background: none;
  color: #008f4a;
  border-radius: 0;
  padding: 2px;
  margin-top: 5px;
}
.product-info {
  flex: 1;
  .each-title-container {
    @include md {
      flex-direction: column;
    }
  }
}
.product-container {
  background: unset !important;
  padding-left: 20px;
  width: 100%;
}

.cost-title {
  margin-bottom: 20px;
  @include md {
    margin-bottom: 14px;
  }
}

.divider {
  border-bottom: 1px solid #c9cccf;
}
.product-variants-list,
.product-description {
  padding: 20px;
}
.product-description img{
width: 200px;
height: auto;
margin: 10px;
border-radius: 10px;
}
.product-description {
  padding-bottom: 0;
}
.card-bg {
  background: #fff;
  padding: 0;
  margin-top: 20px;
  border-radius: 10px;
  border-bottom: 2px solid #e1e3e5;

  overflow: hidden;
  box-shadow: 0px 1px 2px 0px #00000026;
}

.p-d-links {
  border-bottom: 1px solid #e1e3e5;
  padding-bottom: 20px;
}

.product-detail-header {
  padding: 40px 10px;
  margin-top: 30px;
  border-bottom: 1px solid #e1e3e5;
}

.product-detail-header-navigation {
  display: flex;
  align-items: center;
  padding: 10px;
  @include breakpoint(550px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;
  }
}

.product-detail-header-navigation a {
  margin-right: 15px;
}

.product-detail-info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.product-detail-info h2 {
  font-weight: bold;
  font-size: 20px;
  @include md {
    font-size: 16px;
  }
}

.product-detail-info h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #6d7175;
}

.gallery-container {
  padding: 30px;
  padding-top: 0;
}

.zoomer-base-container {
  width: 100% !important;
}

.preview-box {
  width: 100% !important;
  border-radius: 10px !important;
  margin-bottom: 30px !important;
}

.thumb-list {
  width: 100% !important;

  img {
    width: 100px;
    max-height: 100px !important;
    border-radius: 10px !important;
  }
}

.zoomer-control {
  opacity: 0.7 !important;
  width: unset !important;
}

.modal-dialog {
  max-width: 700px !important;
}

.variant-image {
  width: 50px;
  border-radius: 5px;
  box-shadow: 0 20px 50px 10px rgba(29, 41, 54, 0.1);
}

.product-description {
  .title {
    font-size: 1.9rem !important;
    font-weight: 700 !important;
    margin-bottom: 14px;
  }

  .bold-text {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    font-family: "Open Sans";
    font-style: normal;
    line-height: 40px;
    color: #101828;
  }

  .text {
    color: #202223;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400 !important;
    line-height: 24px;
    font-size: 14px;
  }

  .item {
    $font_size_lg: 1.4rem;
    font-weight: 700 !important;
    border: 6px dashed #f2f4f7;
  }
}

.shipping-pricing {
  .flag {
    width: 30px;
    margin-right: 10px;
    vertical-align: middle;
  }

  .country {
    $font_size_sm: 0.875rem;
    font-weight: 600;
    color: #435160;
  }
}

.read-more {
  $font_size_sm: 0.875rem;
  font-weight: 600;
  color: #008f4a;
  cursor: pointer;

  margin-bottom: 20px;
  display: inline;
}

.complete {
  display: none;
}

.more {
  background: lightblue;
  color: navy;
  font-size: 13px;
  padding: 3px;
  cursor: pointer;
}

.product_detail_card {
  padding: 24px 16px;
  gap: 24px;
  font-weight: 700 !important;
  @include lg {
    flex-direction: column-reverse;
  }
}

.v-application ul,
.v-application ol {
  padding-left: 0;
}

.title {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
}

.textship {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
}

.text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600 !important;
  $font_size_sm: 0.875rem !important;
  line-height: 20px;
  color: #475467;
}

.Free_shipping {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #008f4a;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
}

.item {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  color: #344054;
  text-decoration: underline;
  cursor: pointer;
}

.item1 {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
}

.item2 {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  color: #475467;
}

.imgleft {
  margin-bottom: 14px;
  margin-left: -20px;
  margin-right: 9px;
}

.dot {
  border: 1px dashed #f2f4f7;
}

.sample {
  background: #ffffff;
  //display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  $font_size_sm: 0.875rem;
  line-height: 20px;
  color: #1d2939;
  margin-right: 24px;
}

.tags-type {
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: auto;
  margin-top: 3px;
  @include md {
    float: left !important;
    margin-top: 8px;
    margin-left: 0;
  }

  .tag {
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 21px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    height: 25px;
    padding: 6px 8px;
    color: #202223;
    &.Green {
      color: #05603a;
      background: #cde9ce;
    }

    &.Yellow {
      background: #ffeeba;
      color: #724204;
    }

    &.Bluesky {
      background: #b8e7fc;
      color: #012f44;
    }

    &.Red {
      background: #fcebe9;
      color: #661712;
    }

    &.Blue {
      background: #bce3e6;
      color: #042b2e;
    }

    &.Gray {
      background: #e4e7ec;
      color: #1d2939;
    }
  }
}

.theader th {
  background: #e1e3e5 !important ;
  color: #6d7175;
}

.tbody tr:nth-child(even) {
  background: #f5f6f7 !important;
}

.importlist_banner {
  margin-bottom: 36px;
  width: 100%;
}
</style>
